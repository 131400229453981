import { isEmpty } from 'lodash'

const NOTIFICATION_SUBSCRIPTION_LOCAL_STORAGE_KEY =
  'userNotificationSubscriptionData'

const EXPIRY_TIME_SUBSCRIPTION_LOCAL_STORAGE_KEY = 30 * 60 * 1000

function getLocalStorageKey(userId) {
  return `${NOTIFICATION_SUBSCRIPTION_LOCAL_STORAGE_KEY}${userId}`
}

function updateLocalStorage(userId, data, expiryTime) {
  const localStorageKey = getLocalStorageKey(userId)
  const subscriptionData = {
    data,
    expiryTime:
      expiryTime || Date.now() + EXPIRY_TIME_SUBSCRIPTION_LOCAL_STORAGE_KEY
  }

  globalThis.localStorage?.setItem(
    localStorageKey,
    JSON.stringify(subscriptionData)
  )
  return subscriptionData
}

function getLocalStorageData(userId) {
  const localStorageKey = getLocalStorageKey(userId)
  return JSON.parse(globalThis.localStorage?.getItem(localStorageKey)) || {}
}

function clearLocalStorageEANotificationData() {
  if (typeof window === 'undefined') return // Prevent execution on the server side
  Object.keys(globalThis.localStorage).forEach((key) => {
    if (key.startsWith(NOTIFICATION_SUBSCRIPTION_LOCAL_STORAGE_KEY)) {
      globalThis.localStorage.removeItem(key) // Remove the matching key
    }
  })
}

export default () => ({
  namespaced: true,

  state: () => ({
    subscribedVariantIds: []
  }),

  mutations: {
    setNotificationSubscribedVariantIds(state, subscribedVariantIds) {
      state.subscribedVariantIds = subscribedVariantIds
    }
  },

  getters: {
    subscribedVariantIds(state) {
      return state.subscribedVariantIds
    }
  },

  actions: {
    async fetchNotificationSubscribedVariantIds({ commit, rootGetters }) {
      if (!rootGetters['user/loggedIn']) return

      const userId = rootGetters['user/id']
      if (!userId) return

      const storedData = getLocalStorageData(userId)
      let subscribedVariantIds = storedData.data || []

      if (isEmpty(subscribedVariantIds) || storedData.expiryTime < Date.now()) {
        const { data: subscriptions } =
          await this.$repositories.earlyAccessNotificationSubscriptions.index({
            'page[size]': 500
          })
        subscribedVariantIds =
          subscriptions?.flatMap((item) => item.subscribable_ids) || []

        updateLocalStorage(userId, subscribedVariantIds)

        if (subscribedVariantIds.length > 0) {
          commit('setNotificationSubscribedVariantIds', subscribedVariantIds)
        }
      } else {
        commit('setNotificationSubscribedVariantIds', subscribedVariantIds)
      }
    },

    async subscribeToNotification({ commit, rootGetters }, event) {
      if (!rootGetters['user/loggedIn']) return

      const userId = rootGetters['user/id']
      if (!userId) return

      // Subscribe to the variant
      await this.$repositories.subscribeVariantForEarlyAccessNotification.create(
        {
          event_type: event.eventType,
          event_name: event.eventName,
          subscribable_type: 'Variant',
          subscribable_id: event.variantId
        }
      )

      const { data: subscriptions } =
        await this.$repositories.earlyAccessNotificationSubscriptions.index({
          'page[size]': 500
        })
      const subscribedVariantIds =
        subscriptions?.flatMap((item) => item.subscribable_ids) || []

      updateLocalStorage(userId, subscribedVariantIds)

      commit('setNotificationSubscribedVariantIds', subscribedVariantIds)
    },

    clearNotificationSubscribedVariantIds({ commit }) {
      commit('setNotificationSubscribedVariantIds', [])
      clearLocalStorageEANotificationData()
    }
  }
})
