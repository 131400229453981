import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions({
      saveRecentSearch: 'search/saveRecentSearch',
      clearSearch: 'search/clearSearch'
    }),

    slugWithQuery(slug) {
      if (this.queryText.length > 1) {
        return `${slug}?q=${encodeURIComponent(this.queryText)}`
      } else {
        return slug
      }
    }
  }
}
