<template>
  <div class="sidebar-nav-container">
    <SidebarNav>
      <SidebarNavMenuMyAccount />
      <SidebarNavMenuGetStarted v-if="showGetStarted" />
      <SidebarNavMenuItemsContainer
        :categories="categories"
        @toggle-sidebar-nav="$emit('toggle-sidebar-nav')"
      />
      <SidebarLanguageSwitcher
        v-if="$i18n.languages.length > 1"
        :languages="$i18n.languages"
      />
      <SidebarNavItem
        v-if="supportGiftCard"
        :text="$t('layout.navBar.giftCards')"
        :url="'/gift-cards'"
        :level="1"
        :has-separator="false"
        :icon-src="$options.locals.giftCardIcon"
        :icon-alt-text="$t('layout.navBar.giftCards')"
        :additional-attrs="additionalAttrs('beauty-pass > E-GIFT CARDS')"
      />
      <SidebarNavMenuServicesContainer
        :icon-src="$options.locals.beautyPassIcon"
        :links="beautyPassLinks"
        :title="$t('layout.shared.beautyPass')"
      />
      <template v-if="hasOnlineReservationSystem">
        <SidebarNavMenuServicesContainer
          :links="beautyServicesLinks"
          :title="$t('layout.shared.beautyServices')"
          :icon-src="$options.locals.beautyServicesIcon"
        />
        <SidebarNavMenuServicesContainer
          v-if="hasStoreLocations"
          :links="storesAndEventsLinks"
          :title="$t('layout.shared.storesAndEvents')"
          :icon-src="$options.locals.storeIcon"
        />
      </template>
      <SidebarNavItem
        v-else-if="hasStoreLocations"
        :text="storeLocationsLink.label"
        :url="storeLocationsLink.path"
        :level="1"
        :has-separator="false"
        :icon-src="$options.locals.storeIcon"
        :icon-alt-text="storeLocationsLink.label"
      />
      <SidebarNavItem
        :text="$t('layout.navBar.help')"
        :url="'/faq'"
        :level="1"
        :has-separator="false"
        :icon-src="$options.locals.helpIcon"
        :icon-alt-text="$t('layout.navBar.help')"
        :additional-attrs="additionalAttrs('gift-cards > HELP')"
      />
      <SidebarNavMenuLogout
        v-if="userLoggedIn"
        @toggle-sidebar-nav="$emit('toggle-sidebar-nav')"
      />
    </SidebarNav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SidebarNav from '@sephora-asia/core-sidebar-nav/SidebarNav.vue'
import SidebarNavItem from '@sephora-asia/core-sidebar-nav-item/SidebarNavItem.vue'
import bookingServiceMixin from '~/mixins/bookingServiceMixin.js'
import SidebarNavMenuServicesDataTransformer from '~/components/SidebarNavMenuServicesDataTransformer.js'
import SidebarNavMenuItems from '~/components/SidebarNavMenuItems.vue'
import SidebarNavMenuItemsDataTransformer from '~/components/SidebarNavMenuItemsDataTransformer.js'
import SidebarNavMenuLogout from '~/components/SidebarNavMenuLogout.vue'
import SidebarNavMenuMyAccount from '~/components/SidebarNavMenuMyAccount'
import SidebarNavMenuGetStarted from '~/components/SidebarNavMenuGetStarted'
import BeautyPassIcon from '~/assets/images/beauty-pass.svg'
import BeautyServicesIcon from '~/assets/images/beauty-services-calendar-black.svg'
import GiftCardIcon from '~/assets/images/giftcard.svg'
import HelpIcon from '~/assets/images/help.svg'
import RewardsBoutiqueIcon from '~/assets/images/rewards-boutique.svg'
import StoreIcon from '~/assets/images/store-location-black.svg'
import beautyPassMixin from '~/mixins/beautyPassMixin'

const SidebarNavMenuItemsContainer = SidebarNavMenuItemsDataTransformer(
  SidebarNavMenuItems
)
const SidebarNavMenuServicesContainer = SidebarNavMenuServicesDataTransformer(
  SidebarNavMenuItems
)

export default {
  name: 'SidebarNavMenu',

  components: {
    SidebarNav,
    SidebarNavItem,
    SidebarNavMenuServicesContainer,
    SidebarNavMenuItemsContainer,
    SidebarNavMenuLogout,
    SidebarNavMenuMyAccount,
    SidebarNavMenuGetStarted,
    SidebarLanguageSwitcher: () =>
      import('~/components/SidebarLanguageSwitcher.vue')
  },

  mixins: [bookingServiceMixin, beautyPassMixin],

  props: {
    categories: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    ...mapGetters({
      hasStoreLocations: 'globalConfig/hasStoreLocations',
      supportGiftCard: 'globalConfig/supportGiftCard',
      hasOnlineReservationSystem: 'globalConfig/hasOnlineReservationSystem',
      enableSignupRevamp: 'globalConfig/enableSignupRevamp',
      userLoggedIn: 'user/loggedIn',
      isDisplayGetStartedCta: 'user/isDisplayGetStartedCta'
    }),
    showGetStarted() {
      return (
        this.userLoggedIn &&
        this.enableSignupRevamp &&
        this.isDisplayGetStartedCta
      )
    }
  },

  methods: {
    additionalAttrs(label) {
      return {
        'data-analytics-id': 'side-menu',
        'data-analytics-label': label
      }
    }
  },

  locals: {
    beautyPassIcon: BeautyPassIcon,
    beautyServicesIcon: BeautyServicesIcon,
    giftCardIcon: GiftCardIcon,
    helpIcon: HelpIcon,
    rewardsBoutiqueIcon: RewardsBoutiqueIcon,
    storeIcon: StoreIcon
  }
}
</script>

<style lang="scss" scoped>
/deep/ .sidebar-nav {
  background-color: $white;
  box-sizing: border-box;
  height: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  top: 0;
  width: 88%;
  overscroll-behavior: contain;
}
</style>
