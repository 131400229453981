import get from 'lodash/get'

export default () => ({
  namespaced: true,

  state: () => ({
    user: { loggedIn: false }
  }),

  mutations: {
    setUser(state, user) {
      state.user = user
    }
  },

  getters: {
    dateOfBirth(state) {
      // Date is in ISO-8601 format
      const dob = get(state, 'user.attributes.date-of-birth')
      return dob && new Date(dob)
    },

    points(state) {
      return get(state, 'user.attributes.points')
    },

    loggedIn(state) {
      return get(state, 'user.loggedIn')
    },

    firstName(state) {
      return get(state, 'user.attributes.first-name')
    },

    lastName(state) {
      return get(state, 'user.attributes.last-name')
    },

    name(state) {
      return get(state, 'user.attributes.full-name')
    },

    gender(state) {
      return get(state, 'user.attributes.gender')
    },

    id(state) {
      return get(state, 'user.id', '')
    },

    isEmployee(state) {
      return get(state, 'user.attributes.is-employee', false)
    },

    age(state) {
      return get(state, 'user.attributes.age', 0)
    },

    cardNumber(state) {
      return get(state, 'user.attributes.card-number', '')
    },

    emailMD5(state) {
      return get(state, 'user.attributes.email-md5', '')
    },

    emailSHA256(state) {
      return get(state, 'user.attributes.email-sha256', '')
    },

    mobileNumberMD5(state) {
      return get(state, 'user.attributes.mobile-phone-md5', '')
    },

    exclusiveOffers(state) {
      return get(state, 'user.attributes.exclusive-offers', [])
    },

    isDisplayGetStartedCta(state) {
      return get(state, 'user.attributes.display-get-started-cta', false)
    },

    loyaltyTier(state) {
      return get(state, 'user.attributes.loyalty-tier', '')
    },

    loyaltyPoints(state) {
      return get(state, 'user.attributes.points', 0)
    },

    tierPointsCounter(state) {
      return get(state, 'user.attributes.amount-spent', 0)
    }
  }
})
