import consola from 'consola'
import jwtDecode from 'jwt-decode'
import { socialProviderFor } from '~/config/socialProvidersMapping.js'
import {
  DEVICE_ID_COOKIE_KEY,
  JWT_DATA_COOKIE_KEY,
  JWT_SIGNATURE_COOKIE_KEY,
  JWT_REFRESH_TOKEN_COOKIE_KEY,
  JWT_TOKEN_COOKIE_KEY,
  JWT_COOKIE_OPTS
} from '~/config/jwt.js'

const logger = consola.withTag('nuxt:auth-service')
const CART_TOKEN_COOKIE_KEY = 'cart-token'
export const GOOGLE_ONE_TAP_SESSION_STORAGE_KEY =
  'google-one-tap-blocked-details' // this key is shared with luxola

// Taking in app as a dependancy because otherwise we need to cater for the load order of
// axios, cookies, i18n, repository before auth plugin is loaded. This simplifies it.
export default class AuthManager {
  constructor(app, store) {
    this.app = app
    this.store = store
  }

  setJWTCookies(tokenResponseData) {
    const [
      jwtHeader,
      jwtData,
      jwtSignature
    ] = tokenResponseData.access_token.split('.')

    const optsWithHttpOnly = { ...JWT_COOKIE_OPTS, httpOnly: true }

    this.app.$cookies.setAll([
      {
        name: JWT_DATA_COOKIE_KEY,
        value: `${jwtHeader}.${jwtData}`,
        opts: JWT_COOKIE_OPTS
      },
      {
        name: JWT_SIGNATURE_COOKIE_KEY,
        value: jwtSignature,
        opts: optsWithHttpOnly
      },
      {
        name: JWT_REFRESH_TOKEN_COOKIE_KEY,
        value: tokenResponseData.refresh_token,
        opts: optsWithHttpOnly
      }
    ])
  }

  jwtInfo(jwtCookieOpts = {}) {
    // For asymmetric signing method
    const jwtData = this.app.$cookies.get(JWT_DATA_COOKIE_KEY, jwtCookieOpts)
    const jwtSignature = this.app.$cookies.get(
      JWT_SIGNATURE_COOKIE_KEY,
      jwtCookieOpts
    )
    const refreshToken = this.app.$cookies.get(
      JWT_REFRESH_TOKEN_COOKIE_KEY,
      jwtCookieOpts
    )
    const deviceId = this.app.$cookies.get(DEVICE_ID_COOKIE_KEY)

    // For symmetric signing method
    const jwtToken = this.app.$cookies.get(JWT_TOKEN_COOKIE_KEY, jwtCookieOpts)

    if (jwtData && jwtSignature && refreshToken) {
      return {
        signingMethod: 'asymmetric',
        token: `${jwtData}.${jwtSignature}`,
        refreshToken,
        deviceId
      }
    } else if (jwtToken) {
      return {
        signingMethod: 'symmetric',
        token: jwtToken
      }
    } else {
      return false
    }
  }

  async setUser(user) {
    if (user.loggedIn) {
      await this.app.$repositories.users
        .index(
          {},
          {
            // { fromRes: true } option makes $cookies return data from axios response object
            // which in this case will return the newly set (refreshed) JWT info from the cookies
            Authorization:
              this.jwtInfo({ fromRes: true }).token || this.jwtInfo().token
          }
        )
        .then((res) => {
          logger.success('Successfully authenticated')
          user = { ...user, ...res.data }
        })
        .catch(() => {
          logger.info('Authentication failed')
          user = { loggedIn: false }
        })
    }

    this.store.commit('user/setUser', user)
  }

  async socialLogin(provider, token, mergeCart = true) {
    const email = jwtDecode(token).email
    if (
      this.store.getters['globalConfig/enableOktaLogin'] &&
      this.isSephoraEmail(email)
    ) {
      return window.location.assign(
        `/sign_in?return_to=%2F&signin_method=okta&email=${email}`
      )
    }

    let user

    await this.socialSignInRequest(
      socialProviderFor(provider),
      token,
      mergeCart
    )
      .then((res) => {
        this.app.$notify({
          text: this.app.i18n.t('auth.successfulSignIn'),
          type: 'success'
        })
        user = {
          loggedIn: true,
          ...res.included.find((i) => i.type === 'users')
        }

        this.app.$eventBus.$emit('social-sign-in', provider)
      })
      .catch((error) => {
        this.handleSocialSignInError(error)
        user = { loggedIn: false }
      })
    this.store.commit('user/setUser', user)
    this.store.dispatch('cart/fetch')
  }

  async signOut() {
    await this.app.$repositories.sessions
      .destroy()
      .then((_res) => {
        this.setUser({ loggedIn: false })
        this.app.$cookies.remove(JWT_DATA_COOKIE_KEY)
        this.app.$cookies.remove(JWT_SIGNATURE_COOKIE_KEY)
        this.app.$cookies.remove(JWT_TOKEN_COOKIE_KEY)
        this.app.$cookies.remove(CART_TOKEN_COOKIE_KEY)

        this.app.$notify({ text: this.app.i18n.t('auth.signedOut') })
      })
      .catch(() => {})
    this.store.commit('cart/setCart', {})
    this.store.dispatch('cart/fetch')
    this.store.dispatch('waitlist/clearWaitlist')
    this.store.dispatch('wishlist/clearWishlist')
    this.store.dispatch(
      'earlyAccessNotification/clearNotificationSubscribedVariantIds'
    )
  }

  isSephoraEmail(email) {
    return (
      new RegExp(/.*@sephora\..*$/).test(email) ||
      new RegExp(/.*@*\.sephora\..*$/).test(email)
    )
  }

  socialSignInRequest(provider, token, mergeCart) {
    const cartToken = this.app.$cookies.get(CART_TOKEN_COOKIE_KEY)
    return this.app.$repositories.socialAuth.post(
      {
        social: {
          provider,
          provider_token: token,
          merge: mergeCart
        },
        forter_token: window.forterToken
      },
      {},
      { 'X-Cart-Token': cartToken }
    )
  }

  handleSocialSignInError(error) {
    const errorResponse = error?.response?.data?.errors?.[0]

    if (
      errorResponse &&
      errorResponse.status === '404' &&
      errorResponse.detail === 'User not found'
    ) {
      window.location.assign(
        `/sign_in?msg=${this.app.i18n.t('auth.promptSignUp')}`
      )
    } else {
      this.app.$notify({
        text: this.app.i18n.t('auth.failed'),
        type: 'error'
      })
    }
  }

  // called by createAuthRefreshInterceptor
  handleAuthBlocked(errorResponse, errorMeta) {
    const oneTapBlockedDetails = {
      detail: errorResponse?.detail,
      description: errorMeta?.error_description,
      reference_id: errorMeta?.reference_id
    }
    window.sessionStorage.setItem(
      GOOGLE_ONE_TAP_SESSION_STORAGE_KEY,
      JSON.stringify(oneTapBlockedDetails)
    )
    window.location.assign('/sign_in')
  }
}
