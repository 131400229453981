import { render, staticRenderFns } from "./default.vue?vue&type=template&id=5131c629&scoped=true&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./default.vue?vue&type=style&index=1&id=5131c629&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5131c629",
  null
  
)

export default component.exports