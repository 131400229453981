<template>
  <Layout>
    <template #sidebar-nav>
      <SidebarNavSlider
        v-show="showSidebarNav"
        :show-sidebar-nav="showSidebarNav"
        @toggle-sidebar-nav="toggleSidebarNav"
      />
    </template>
    <template #mobile-navbar>
      <TopNavigationMobile
        :line-item-count="lineItemsCount"
        @toggle-sidebar-nav="toggleSidebarNav"
      />
    </template>
    <template #mobile-app-banner>
      <AppsflyerBannerContainer v-if="$device.isMobile" />
    </template>
    <template #desktop-navbar>
      <TopNavigation>
        <template #level-left>
          <template v-if="loggedIn">
            <TopNavigationDropdownMyAccount />
            <span class="is-separator">|</span>
            <TopNavigationUserPoints />
          </template>
          <template v-else>
            <TopNavigationSignIn />
            <span class="is-separator">/</span>
            <TopNavigationSignUp />
          </template>
          <TopNavigationDropdownBeautyPass />
          <TopNavigationGetStarted v-if="showGetStarted" />
        </template>
        <template #level-right>
          <TopNavigationDropdownGetApp />
          <TopNavigationStoresServices
            v-if="hasOnlineReservationSystem || hasStoreLocations"
          />
          <TopNavigationLanguageSwitcher
            v-if="$i18n.languages.length > 1"
            :languages="$i18n.languages"
          />
          <TopNavigationWishlist />
          <TopNavigationDropdownCartContainer />
        </template>
      </TopNavigation>
    </template>
    <template #notification-area>
      <Toast />
    </template>
    <template v-if="$device.isDesktopOrTablet" #header-banner>
      <HeaderBannerContainer />
    </template>
    <template v-if="$device.isMobile" #mobile-header-banner>
      <HeaderBannerContainer />
    </template>
    <template #logo>
      <SephoraLogoContainer />
    </template>
    <template #searchbar>
      <SearchBarContainer @focused="fixBody" />
    </template>
    <template #promotion>
      <PromotionBannerContainer />
    </template>
    <template #menu>
      <NavBarContainer v-if="!$device.isMobile" />
    </template>
    <template #content>
      <nuxt />
    </template>
    <template #footer>
      <FooterContainer />
      <client-only>
        <InitDataLayerContainer />
        <GoogleOneTap v-if="!loggedIn && nuxtReady" />
        <GlobalEventsHandler />
        <BrazeInitializer v-if="nuxtReady" />
        <ZendeskWebWidget
          class="is-hidden-mobile"
          :language-code="$i18n.locale"
          :label="$t('thirdParty.zendesk.webWidget.label')"
          text-color="#fff"
          bg-color="#0f0f0f"
          :widget-key="zendeskWidgetKey"
        />
        <LegalNotice
          v-if="showLegalNotice && legalNotice"
          :legal-notice="legalNotice"
          @close-toast="closeLegalNoticeToast"
        />
      </client-only>
    </template>
    <template #footer-copyright>
      <FooterLegalInfo />
      <FooterCopyright
        :entity-name="organization.entityName"
        :privacy-policy-label="$t('layout.footer.privacyPolicy')"
        :terms-of-use-label="$t('layout.footer.termsOfUse')"
      />
    </template>
    <template #modal>
      <client-only>
        <portal-target name="modal-container" />
        <Overlay />
        <MobileLandscapeBlocker v-if="$device.isMobile" />
      </client-only>
    </template>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex'
import Layout from '@sephora-asia/core-layout/Layout.vue'
import TopNavigation from '@sephora-asia/core-top-navigation/TopNavigation.vue'
import FooterContainer from '~/components/FooterContainer.vue'
import FooterCopyright from '~/components/FooterCopyright.vue'
import FooterLegalInfo from '~/components/FooterLegalInfo.vue'
import AppsflyerBannerContainer from '~/components/AppsflyerBannerContainer'
import GlobalEventsHandler from '~/components/GlobalEventsHandler'
import HeaderBannerContainer from '~/components/HeaderBannerContainer.vue'
import InitDataLayerContainer from '~/components/InitDataLayerContainer.vue'
import NavBarContainer from '~/components/NavBarContainer.vue'
import PromotionBannerContainer from '~/components/PromotionBannerContainer.vue'
import SearchBarContainer from '~/components/SearchBarContainer.vue'
import SephoraLogoContainer from '~/components/SephoraLogoContainer.vue'
import SidebarNavSlider from '~/components/SidebarNavSlider.vue'
import Toast from '~/components/Toast.vue'
import TopNavigationDropdownBeautyPass from '~/components/TopNavigationDropdownBeautyPass.vue'
import TopNavigationGetStarted from '~/components/TopNavigationGetStarted.vue'
import TopNavigationDropdownMyAccount from '~/components/TopNavigationDropdownMyAccount.vue'
import TopNavigationDropdownGetApp from '~/components/TopNavigationDropdownGetApp.vue'
import TopNavigationStoresServices from '~/components/TopNavigationStoresServices.vue'
import TopNavigationMobile from '~/components/TopNavigationMobile.vue'
import TopNavigationSignIn from '~/components/TopNavigationSignIn.vue'
import TopNavigationSignUp from '~/components/TopNavigationSignUp.vue'
import TopNavigationUserPoints from '~/components/TopNavigationUserPoints.vue'
import TopNavigationWishlist from '~/components/TopNavigationWishlist.vue'
import TopNavigationDropdownCartContainer from '~/components/TopNavigationDropdownCartContainer.vue'
import Overlay from '~/components/Overlay.vue'
import LegalNotice from '~/components/LegalNotice.vue'

export default {
  name: 'Default',

  components: {
    MobileLandscapeBlocker: () =>
      import('~/components/MobileLandscapeBlocker.vue'),
    BrazeInitializer: () => import('~/components/BrazeInitializer'),
    FooterContainer,
    FooterCopyright,
    FooterLegalInfo,
    AppsflyerBannerContainer,
    GlobalEventsHandler,
    GoogleOneTap: () => import('~/components/GoogleOneTap.vue'),
    HeaderBannerContainer,
    InitDataLayerContainer,
    Layout,
    NavBarContainer,
    PromotionBannerContainer,
    SearchBarContainer,
    SephoraLogoContainer,
    SidebarNavSlider,
    Toast,
    TopNavigation,
    TopNavigationDropdownBeautyPass,
    TopNavigationGetStarted,
    TopNavigationDropdownGetApp,
    TopNavigationDropdownMyAccount,
    TopNavigationStoresServices,
    TopNavigationMobile,
    TopNavigationSignIn,
    TopNavigationSignUp,
    TopNavigationUserPoints,
    TopNavigationWishlist,
    TopNavigationDropdownCartContainer,
    Overlay,
    LegalNotice,
    TopNavigationLanguageSwitcher: () =>
      import('~/components/TopNavigationLanguageSwitcher.vue'),
    ZendeskWebWidget: () =>
      import(
        '@sephora-asia/third-party-zendesk-web-widget/ZendeskWebWidget.vue'
      )
  },

  data() {
    return {
      showSidebarNav: false,
      isBodyFixed: false,
      nuxtReady: false,
      showLegalNotice: false
    }
  },

  async fetch() {
    const fetchList = [
      this.$store.dispatch('layoutConfig/fetchLayoutConfig'),
      this.$store.dispatch('category/fetchAll'),
      this.$store.dispatch('brand/fetchAll'),
      this.$store.dispatch('cart/fetchLineItemsCount'),
      this.$store.dispatch('wishlist/fetchWishlist')
    ]

    await Promise.all(fetchList)
  },

  head() {
    const localesOrganization = this.$t('schema').organization
    const localesCountry = this.$t('countries')
    let socialURLs = []
    if (this.social) {
      socialURLs = [
        this.social.facebook && this.social.facebook.url,
        this.social.instagram && this.social.instagram.url,
        this.social.linkedin && this.social.linkedin.url,
        this.social.youtube && this.social.youtube.url,
        this.social.snapchat && this.social.snapchat.url,
        this.social.twitter && this.social.twitter.url
      ].filter((x) => !!x)
    }
    return {
      bodyAttrs: {
        class: this.isBodyFixed ? 'fixed' : null
      },
      script: [
        {
          json: {
            '@context': 'https://schema.org',
            '@type': 'Organization',
            name: `Sephora ${
              this.organization.country ||
              localesCountry[this.$i18n.countryCode]
            }`,
            sameAs: socialURLs,
            url: `https://${this.website.domain}`,
            address: {
              '@type': 'PostalAddress',
              addressCountry:
                this.organization.country ||
                localesCountry[this.$i18n.countryCode]
            },
            logo: this.website.logo,
            description:
              this.organization.schema_description ||
              localesOrganization.description
          },
          type: 'application/ld+json'
        },
        {
          json: {
            '@context': 'https://schema.org',
            '@type': 'Website',
            url: `https://${this.website.domain}`,
            potentialAction: [
              {
                '@type': 'searchAction',
                target: {
                  '@type': 'EntryPoint',
                  urlTemplate: `https://${this.website.domain}/search?q={search_term_string}`
                },
                'query-input': 'required name=search_term_string'
              },
              {
                '@type': 'searchAction',
                target: {
                  '@type': 'EntryPoint',
                  urlTemplate: 'sephora://search/?q={search_term_string}'
                },
                'query-input': 'required name=search_term_string'
              }
            ]
          },
          type: 'application/ld+json'
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      hasStoreLocations: 'globalConfig/hasStoreLocations',
      lineItems: 'cart/lineItems',
      lineItemsCount: 'cart/lineItemsCount',
      loggedIn: 'user/loggedIn',
      isDisplayGetStartedCta: 'user/isDisplayGetStartedCta',
      organization: 'globalConfig/organization',
      hasOnlineReservationSystem: 'globalConfig/hasOnlineReservationSystem',
      zendeskWidgetKey: 'globalConfig/zendeskWidgetKey',
      website: 'globalConfig/website',
      social: 'globalConfig/social',
      customerService: 'globalConfig/customerService',
      enableSignupRevamp: 'globalConfig/enableSignupRevamp',
      legalNotice: 'globalConfig/legalNotice'
    }),
    showGetStarted() {
      return (
        this.loggedIn && this.enableSignupRevamp && this.isDisplayGetStartedCta
      )
    }
  },

  mounted() {
    window.onNuxtReady(() => {
      this.nuxtReady = true
    })
    // Create event bus using nuxt to emit event between multiple components
    this.$nuxt.$on('show-modal', (value) => {
      this.fixBody(value)
    })
    this.isLegalNoticeVisible()
  },

  beforeDestroy() {
    this.$nuxt.$off('show-modal', (value) => {
      this.fixBody(value)
    })
  },

  methods: {
    toggleSidebarNav() {
      this.showSidebarNav = !this.showSidebarNav
      this.fixBody(this.showSidebarNav)
    },
    fixBody(value) {
      this.isBodyFixed = value
    },
    closeLegalNoticeToast() {
      this.showLegalNotice = false
    },
    isLegalNoticeVisible() {
      const cookie = this.$cookies.get('legal_notice')
      if (!cookie) {
        this.showLegalNotice = true
      }
    }
  }
}
</script>

<style lang="scss">
html,
body {
  height: 100%;
  width: 100%;
  overflow: unset;
}

body.fixed {
  overflow: hidden;
}

// Disable scrolling when MobileLandScapeBlocker is shown
// note: aspect ratio is required to handle incorrect orientation reported on
// some android browsers when keyboard is visible
@include mobile {
  @media screen and (orientation: landscape) and (min-aspect-ratio: 13/9) {
    body {
      overflow: hidden !important;
    }
  }
}
</style>

<style lang="scss" scoped>
::v-deep .top-navigation {
  .dropdown-menu {
    padding-top: 8px;
  }
}

.is-separator {
  color: $white;
  font-weight: 500;
  margin-left: 3px;
  margin-right: 3px;
}

.logo {
  vertical-align: bottom;
}
</style>
