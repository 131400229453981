<template>
  <nav class="top-navigation-mobile columns level is-marginless is-mobile">
    <div class="column level-left is-one-quarter">
      <img
        :src="$options.locals.menuIcon"
        :alt="$t('layout.navigation.menu')"
        class="top-navigation-icon"
        data-analytics-id="hamburger"
        data-analytics-label="Click Hamburger"
        @click="$emit('toggle-sidebar-nav')"
      />
    </div>
    <div class="column is-half">
      <SmartLink to="/" :native="false">
        <img
          :src="$options.locals.sephoraLogo"
          :alt="$t('layout.navigation.home')"
          class="top-navigation-logo"
        />
      </SmartLink>
    </div>
    <div class="column level-right is-one-quarter">
      <SmartLink :native="false" to="/account/dashboard">
        <img
          :src="$options.locals.myAccountIcon"
          :alt="$t('layout.navigation.myAccount')"
          class="top-navigation-icon"
        />
      </SmartLink>
      <SmartLink :native="false" to="/cart">
        <Badge
          :content="lineItemCount.toString()"
          :aria-label="
            $t('layout.cart.itemsCountAriaLabel', {}, { count: lineItemCount })
          "
          horizontal-position="right"
          vertical-position="top"
          offset-x="0"
          offset-y="-3px"
          size="compact"
          :hidden="lineItemCount === 0"
          data-analytics-id="header-click"
          data-analytics-label="cart"
        >
          <img
            :src="$options.locals.bagIcon"
            :alt="$t('layout.navigation.bag')"
            class="top-navigation-icon"
          />
        </Badge>
      </SmartLink>
    </div>
  </nav>
</template>

<script>
import Badge from '@sephora-asia/core-badge/Badge.vue'
import BagIcon from '~/assets/images/bag-black.svg'
import MenuIcon from '~/assets/images/menu-black.svg'
import MyAccountIcon from '~/assets/images/my-account-black.svg'
import SephoraLogo from '~/assets/images/sephora-logo.svg'

export default {
  name: 'TopNavigationMobile',

  components: {
    Badge
  },

  props: {
    lineItemCount: {
      type: Number,
      default: 0
    }
  },

  locals: {
    bagIcon: BagIcon,
    menuIcon: MenuIcon,
    myAccountIcon: MyAccountIcon,
    sephoraLogo: SephoraLogo
  }
}
</script>

<style lang="scss" scoped>
@import '~bulma/sass/components/level';

.top-navigation-mobile {
  background-color: $white;

  .top-navigation-logo {
    img {
      vertical-align: middle;
      max-height: 30px;
    }
  }

  .top-navigation-icon {
    width: 26px;
    height: 26px;
    vertical-align: middle;
    margin-right: 5px;

    img {
      width: 24px;
      height: 24px;
    }
  }
}
</style>
