import Bugsnag from '@bugsnag/js'
import SearchItemsTransformer from '~/api/SearchItemsTransformer.js'
import RecentProductsTransformer from '~/api/RecentProductsTransformer.js'
import SearchPopularQueriesTransformer from '~/api/SearchPopularQueriesTransformer.js'

export const state = () => ({
  popularQueries: [],
  recentSearches: [],
  recentProducts: [],
  suggestedBrands: [],
  suggestedCategories: [],
  suggestedProducts: [],
  searchQueryId: {}
})

export const mutations = {
  setPopularQueries(state, popularQueries) {
    state.popularQueries = popularQueries
  },
  setRecentSearches(state, recentSearches) {
    state.recentSearches = recentSearches
  },
  setRecentProducts(state, recentProducts) {
    state.recentProducts = recentProducts
  },
  setSuggestedBrands(state, suggestedBrands) {
    state.suggestedBrands = suggestedBrands
  },
  setSuggestedCategories(state, suggestedCategories) {
    state.suggestedCategories = suggestedCategories
  },
  setSuggestedProducts(state, suggestedProducts) {
    state.suggestedProducts = suggestedProducts
  },
  setSearchQueryId(state, searchQueryId) {
    state.searchQueryId = searchQueryId
  }
}

export const getters = {
  popularQueries(state) {
    return state.popularQueries
  },
  recentSearches(state) {
    return state.recentSearches
  },
  recentProducts(state) {
    return state.recentProducts
  },
  suggestedBrands(state) {
    return state.suggestedBrands
  },
  suggestedCategories(state) {
    return state.suggestedCategories
  },
  suggestedProducts(state) {
    return state.suggestedProducts
  },
  searchQueryId(state) {
    return state.searchQueryId
  }
}

export const actions = {
  async fetchPopularQueries({ commit }) {
    const { data } = await this.$repositories.popularQuerySearch
      .index()
      .catch((e) => {
        Bugsnag.notify(e)
      })

    if (data) {
      commit(
        'setPopularQueries',
        new SearchPopularQueriesTransformer(data).transformedData
      )
    }
  },

  async fetchAll({ commit }, query) {
    const searchData = await this.$repositories.searchWithEarlyAccess
      .index({
        include: 'brands,categories',
        query
      })
      .catch((e) => {
        Bugsnag.notify(e)
      })

    if (!searchData?.results) return

    const language = this.app.i18n.locale

    commit(
      'setSuggestedBrands',
      new SearchItemsTransformer(searchData.results.brands, { language })
        .transformedData
    )

    commit(
      'setSuggestedCategories',
      new SearchItemsTransformer(searchData.results.categories, {
        language
      }).transformedData
    )

    commit(
      'setSuggestedProducts',
      new SearchItemsTransformer(searchData.results.variants, {
        language
      }).transformedData
    )

    commit('setSearchQueryId', {
      brand: searchData.results.brands.queryID,
      category: searchData.results.categories.queryID,
      variant: searchData.results.variants.queryID
    })
  },

  fetchRecentSearches({ commit }) {
    let recentSearches

    try {
      recentSearches = JSON.parse(localStorage.getItem('recentSearches')) || []
    } catch {
      recentSearches = []
    }

    commit('setRecentSearches', recentSearches)
  },

  fetchRecentProducts({ commit }) {
    let recentProducts

    try {
      recentProducts = JSON.parse(localStorage.getItem('recentProducts')) || []
    } catch {
      recentProducts = []
    }

    commit(
      'setRecentProducts',
      new RecentProductsTransformer(recentProducts).transformedData
    )
  },

  saveRecentSearch({ commit }, searchItem) {
    let currentRecentSearches = []

    // fetch the current recent search list from local storage
    try {
      currentRecentSearches =
        JSON.parse(localStorage.getItem('recentSearches')) || []
    } catch {
      currentRecentSearches = []
    }

    // find duplicate search item in current recent search array list and remove it
    if (currentRecentSearches.length > 0) {
      const recentSearchIndex = currentRecentSearches.findIndex(
        (s) => s.name.toLowerCase() === searchItem.name.toLowerCase()
      )
      if (recentSearchIndex !== -1) {
        currentRecentSearches.splice(recentSearchIndex, 1)
      }
    }

    // push search item into top of the array list
    currentRecentSearches.unshift(searchItem)

    // remove extra recent searches (max 5)
    if (currentRecentSearches.length > 5) {
      currentRecentSearches.pop()
    }

    // Save recent searches into state
    commit('setRecentSearches', currentRecentSearches)
    // Save recent searches into local storage
    localStorage.setItem(
      'recentSearches',
      JSON.stringify(currentRecentSearches)
    )
  },

  clearSearch({ commit }, type) {
    // Clear state
    if (type === 'recentSearches') {
      commit('setRecentSearches', [])
    }
    if (type === 'recentProducts') {
      commit('setRecentProducts', [])
    }
    // Clear localstorage
    localStorage.removeItem(type)
  }
}
