<template>
  <NavItem>
    <Dropdown class="nav-item is-hoverable" :boundary="boundary">
      <a
        :data-analytics-label="navBrandsTitle.text"
        :data-analytics-path="getFormattedPath(navBrandsTitle.url)"
        :href="navBrandsTitle.url"
        data-analytics-id="top-menu"
        @mouseover="
          trackTopMenuHover(
            'top_menu_hovered',
            getFormattedPath(navBrandsTitle.url)
          )
        "
        @click="preventDefault"
      >
        <NavTitle :text="navBrandsTitle.text" :is-presentation="true" />
      </a>
      <template slot="content">
        <NavPane>
          <NavBrands
            :search-box-label="$t('layout.navBar.searchBoxLabel')"
            :brands-by-letters="brandsData"
            @searchByLetter="searchByLetter"
            @enterBrandSearch="enterBrandSearch"
          >
            <template #brandQuickLink>
              <a
                :href="sephoraCollectionLink"
                class="menu-sub-title"
                data-analytics-id="top-sub-menu"
                :data-analytics-label="sephoraCollectionLink"
                :data-analytics-path="getFormattedPath(sephoraCollectionLink)"
              >
                Shop Sephora Collection
              </a>
            </template>

            <template #brand-link="slotProps">
              <a
                :href="slotProps.brand.link"
                class="item-link"
                tabindex="0"
                data-analytics-id="top-sub-menu"
                :data-analytics-label="slotProps.brand.label"
                :data-analytics-path="getFormattedPath(slotProps.brand.link)"
              >
                {{ slotProps.brand.label }}
              </a>
            </template>
          </NavBrands>
        </NavPane>
      </template>
    </Dropdown>
  </NavItem>
</template>

<script>
import Dropdown from '@sephora-asia/core-dropdown/Dropdown.vue'
import NavBrands from '@sephora-asia/core-nav-brands/NavBrands.vue'
import NavItem from '@sephora-asia/core-nav-item/NavItem.vue'
import NavPane from '@sephora-asia/core-nav-pane/NavPane.vue'
import NavTitle from '@sephora-asia/core-nav-title/NavTitle.vue'
import dataAnalyticsMixin from '~/mixins/dataAnalyticsMixin.js'

export default {
  name: 'NavBarBrands',
  components: {
    Dropdown,
    NavBrands,
    NavItem,
    NavPane,
    NavTitle
  },

  mixins: [dataAnalyticsMixin],

  props: {
    navBrandsTitle: {
      type: Object,
      default: () => {}
    },
    brandsData: {
      type: Object,
      default: () => {}
    },
    boundary: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      sephoraCollectionLink: '/brands/sephora-collection'
    }
  },

  methods: {
    preventDefault(e) {
      if (this.$device.isTablet) {
        e.preventDefault()
      }
    },
    searchByLetter(letter) {
      const opts = {
        eventCategory: 'Top menu',
        eventAction: 'Click',
        eventLabel: 'brands > ' + letter + '*'
      }
      this.$analytics.trackEvent('ua.event', opts)
    },
    enterBrandSearch(object) {
      const opts = {
        eventCategory: 'Top menu',
        eventAction: 'Click',
        eventLabel: 'brands > search bar click'
      }
      this.$analytics.trackEvent('ua.event', opts)
    }
  }
}
</script>
<style lang="scss" scoped>
.level-item {
  display: contents;
}

.brands-list {
  text-align: left;
}

.brands-search {
  text-align: left;
}

// fix for hovering on scrollbar in safari triggers
// visibility from hidden to become visible

/deep/ .dropdown-menu {
  .brands-list {
    overflow-y: hidden;
  }
}

/deep/ .dropdown.is-active,
/deep/ .dropdown.is-hoverable:hover {
  .dropdown-menu {
    .brands-list {
      overflow-y: scroll;
    }
  }
}
</style>
